import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticleAccordion, LazyArticleCTA, LazyArticleCTAImage, LazyArticleFinePrint, LazyArticleGreyBlock, LazyArticleHero, LazyArticleImage, LazyArticleQuote, LazyArticleTable, LazyArticleYoutube, LazyAuthorDetails, LazyBlogPost, LazyCodeBlock, LazyHelpCentrePost, LazyMediaPost } from '#components'
const lazyGlobalComponents = [
  ["ArticleAccordion", LazyArticleAccordion],
["ArticleCTA", LazyArticleCTA],
["ArticleCTAImage", LazyArticleCTAImage],
["ArticleFinePrint", LazyArticleFinePrint],
["ArticleGreyBlock", LazyArticleGreyBlock],
["ArticleHero", LazyArticleHero],
["ArticleImage", LazyArticleImage],
["ArticleQuote", LazyArticleQuote],
["ArticleTable", LazyArticleTable],
["ArticleYoutube", LazyArticleYoutube],
["AuthorDetails", LazyAuthorDetails],
["BlogPost", LazyBlogPost],
["CodeBlock", LazyCodeBlock],
["HelpCentrePost", LazyHelpCentrePost],
["MediaPost", LazyMediaPost],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
