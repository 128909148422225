<template>
  <component
    :is="componentType"
    v-bind="componentAttributes"
    class="relative flex h-fit items-center rounded-full border-2 no-underline outline-offset-4 focus:outline-4 motion-safe:transition-colors"
    :class="{
      'border-green-800 bg-green-800 fill-white text-white outline-green-800 hover:border-green-900 hover:bg-green-900 focus:border-green-900 focus:bg-green-900 active:border-green-950 active:bg-green-950':
        !onDark,
      'border-green-400 bg-green-400 fill-jag-1000 text-jag-1000 outline-green-400 hover:border-green-300 hover:bg-green-300 focus:border-green-300 focus:bg-green-300 active:border-green-200 active:bg-green-200':
        onDark,
      '!border-grey-300 !bg-grey-300 !fill-grey-400 !text-grey-400 mix-blend-luminosity':
        disabled && !onDark,
      '!border-grey-600 !bg-grey-600 !fill-grey-400 !text-grey-400 mix-blend-luminosity':
        disabled && onDark,
    }"
  >
    <div
      class="flex w-full items-center justify-center rounded-full"
      :class="{
        'gap-1.5 px-3 py-2.5 shadow-emboss-sm': size === 'small',
        'gap-2 px-4 py-3 shadow-emboss': size === 'medium',
        'gap-2 p-4 shadow-emboss': size === 'large',
        '!shadow-none': disabled,
      }"
    >
      <component
        :is="iconLeft"
        v-if="iconLeft"
        class="shrink-0"
        :class="[iconSize, { invisible: loading }]"
      />
      <div
        v-if="loading"
        class="absolute left-0 top-0 flex h-full w-full items-center justify-center"
      >
        <IconLoaderSmall class="fill-inherit" :class="iconSize" />
        <span class="sr-only">Loading…</span>
      </div>
      <div
        class="font-bold uppercase italic leading-none tracking-wider"
        :class="{
          'text-xs': size === 'small',
          'text-sm': size === 'medium',
          'text-base': size === 'large',
          'ml-1': !iconLeft,
          invisible: loading,
        }"
      >
        {{ label }}
      </div>
      <component
        :is="iconRight"
        v-if="iconRight"
        class="shrink-0"
        :class="[iconSize, { invisible: loading }]"
      />
    </div>
  </component>
</template>
<script setup lang="ts">
type Arrows = "right" | "upRight" | "none";

const props = defineProps<{
  arrow: Arrows;
  size: "large" | "medium" | "small";
  label: string;
  url?: string;
  urlNewWindow?: boolean;
  iconLeft?: Component;
  loading?: boolean;
  disabled?: boolean;
  onDark?: boolean;
}>();

const componentType = computed(() =>
  props.url ? resolveComponent("NuxtLink") : "button",
);

const componentAttributes = computed(() => {
  return props.url
    ? {
        to: props.url,
        target: props.urlNewWindow ? "_blank" : undefined,
      }
    : {
        type: "button",
        disabled: props.disabled || props.loading,
      };
});

const iconRight = computed(() => {
  if (props.arrow === "right") return resolveComponent("IconArrowRight");
  if (props.arrow === "upRight") return resolveComponent("IconArrowUpRight");
  return null;
});

const iconSize = computed(() => {
  if (props.size === "large") return "size-4";
  if (props.size === "medium") return "size-3.5";
  if (props.size === "small") return "size-3";
  return null;
});
</script>
