import type { RouterOptions } from "@nuxt/schema";
import { subdomainMap } from "~/subdomains";

export default <RouterOptions>{
  routes: (_routes) => {
    const urlObj = useRequestURL();
    const subdomainObj = subdomainMap.get(urlObj.host);

    if (subdomainObj) {
      const newRoutes = _routes.filter((route) => route.name !== "index");
      const userRouteMapped = newRoutes.map((i) => ({
        ...i,
        path:
          i.path === `${subdomainObj?.baseUrl}`
            ? i.path.replace(`${subdomainObj?.baseUrl}`, "/")
            : i.path.replace(`${subdomainObj?.baseUrl}/`, "/"),
      }));
      return userRouteMapped;
    }
  },
};
