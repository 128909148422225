<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M17.8949 6.85461C17.8284 6.69374 17.7302 6.54287 17.6004 6.41151C17.5965 6.40753 17.5925 6.40358 17.5885 6.39966C17.3384 6.15255 16.9947 6 16.6154 6H7.38462C6.61991 6 6 6.61991 6 7.38462C6 8.14932 6.61991 8.76923 7.38462 8.76923H13.2726L6.40554 15.6363C5.86482 16.177 5.86482 17.0537 6.40554 17.5945C6.94627 18.1352 7.82296 18.1352 8.36369 17.5945L15.2308 10.7274V16.6154C15.2308 17.3801 15.8507 18 16.6154 18C17.3801 18 18 17.3801 18 16.6154V7.38462C18 7.19688 17.9626 7.01786 17.8949 6.85461Z"
    />
  </svg>
</template>
