<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M12.9983 4.4135C12.4469 3.86217 11.553 3.86217 11.0017 4.4135C10.4504 4.96482 10.4504 5.8587 11.0017 6.41003L15.1799 10.5882H5.41176C4.63207 10.5882 4 11.2203 4 12C4 12.7797 4.63207 13.4118 5.41176 13.4118H15.1799L11.0017 17.59C10.4504 18.1413 10.4504 19.0352 11.0017 19.5865C11.553 20.1378 12.4469 20.1378 12.9983 19.5865L19.5833 13.0015C19.5937 12.9912 19.6039 12.9807 19.614 12.97C19.7322 12.8451 19.8233 12.7039 19.8872 12.5539C19.9598 12.3838 20 12.1966 20 12C20 11.8025 19.9594 11.6144 19.8862 11.4437C19.824 11.2985 19.7363 11.1615 19.6231 11.0397C19.6098 11.0254 19.5963 11.0114 19.5824 10.9977L12.9983 4.4135Z"
    />
  </svg>
</template>
