/*
This duplicates the same behaviour as statamic to ensure cross server compatibility

  USAGE 
  // in script
  // const utms = () => useState('utms')
  // Object = utms()
  // should move to pinia when structure defined
*/

export default defineNuxtRouteMiddleware((to) => {
  // skip middleware on server
  if (import.meta.server) return;

  const nuxtApp = useNuxtApp();

  // get utms from localstorage
  const utmsData = localStorage.getItem("_abbuc_");
  const utms: Utms = JSON.parse(utmsData!) || {};

  // initial client load
  if (import.meta.client && nuxtApp.isHydrating) {
    const cleanUrl = () => {
      const cleanSearch = window.location.search
        .replace(/utm_[^&]+&?/g, "")
        .replace(/&$/, "")
        .replace(/^\?$/, "");
      return (
        window.location.origin +
        window.location.pathname +
        cleanSearch +
        window.location.hash
      );
    };

    const referer = document.referrer;
    // const currentPath = window.location.pathname;
    // const landingPage = window.location.href;
    const isInvalidReferrer = referer === "" || referer === void 0;
    const sameDomainReferer = referer.includes(window.location.hostname);

    const URlParams = Object.fromEntries(new URLSearchParams(location.search));

    utms.IREFERRER = utms.IREFERRER || isInvalidReferrer ? "direct" : referer;
    utms.ILANDPAGE = utms.ILANDPAGE || cleanUrl();

    if (!sameDomainReferer) {
      utms.LREFERRER = isInvalidReferrer ? "direct" : referer;
    }

    utms.USOURCE ??= URlParams["utm_source"];
    utms.UMEDIUM ??= URlParams["utm_medium"];
    utms.UCAMPAIGN ??= URlParams["utm_campaign"];
    utms.UTERM ??= URlParams["utm_term"];
    utms.UCONTENT ??= URlParams["utm_content"];
    utms.rokttrackingid ??= URlParams["rtid"];

    for (const [key, value] of Object.entries(URlParams)) {
      if (key.includes("clid")) {
        utms.ad_click_id = value;
        utms.ad_click_provider ??= URlParams["utm_source"];
        break;
      }
    }

    // add source attribution on every initial visit
    if (URlParams["utm_source"]) {
      const attribution = utms.source_attribution || [];
      const index = attribution.indexOf(URlParams["utm_source"]);
      if (index > -1) {
        attribution.splice(index, 1);
      }
      attribution.push(URlParams["utm_source"]);
      if (attribution.length > 10) attribution.shift();
      utms.source_attribution = attribution;
    }
  }

  // add interest on every route change
  const addInterest = (interests: string[], path: string) => {
    const pagePath = path.split("/")[1] == "cart" ? "/cart" : path;
    if (!interests.includes(pagePath)) {
      interests.push(pagePath);
      if (interests.length > 10) interests.shift(); // remove first 1 if greater than 10
    }
    return interests;
  };

  utms.interests = addInterest(utms.interests || [], to.path);

  // write utms to localstorage
  localStorage.setItem("_abbuc_", JSON.stringify(utms));

  // set state
  useState<Utms>("utms", () => utms);
});
